import { createGlobalStyle } from "styled-components";
import titleFont from "../../assets/fonts/TT Norms Pro Bold/TypeType - TT Norms Pro Bold.ttf";
import textFont from "../../assets/fonts/Inter/static/Inter-Regular.ttf";

export * from "./layout";
export * from "./text";
export * from "./images";
export * from "./buttons/GithubLogin";
export * from "./InstructorUI";
export * from "./theme";
export * from "./icons";
export * from "./TimeDisplay";
export * from "./DifficultyDropDown";
export * from "./CodeBlock";
export * from "./BookStyles";
export * from "./PrevNextMenu";

export const GlobalStyle = createGlobalStyle`  

  @font-face {
    font-family: 'Title';
    src: url(${titleFont}) format('woff2');
  }

  @font-face {
    font-family: 'Text';
    src: url(${textFont}) format('woff2');
  }

  html, body, #root {
    height: 100%;
    padding: 0;
    margin: 0;
  }

  h1, h2, h3, p, td, span, div {
    padding: 0;
    margin: 0;
  }

`;
