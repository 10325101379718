import { useEffect } from "react";
import { useSearchParams, Navigate } from "react-router-dom";
import { gql, useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GithubLogin, InstructorUI } from "./ui";
import styled from "styled-components";

const exist = (x) => x;

export const ME = gql`
  query instructor {
    me {
      avatar
      login
      email
      name
    }
  }
`;

export const GITHUB_AUTH_LINK = gql`
  query GithubAuthLink {
    githubUrl
  }
`;

export const AUTHORIZE = gql`
  mutation auth($code: String!) {
    githubAuth(code: $code) {
      token
      instructor {
        avatar
        email
        login
      }
    }
  }
`;

export function LoginRequired() {
  const client = useApolloClient();
  const isLoggedIn = client.readQuery({ query: ME });
  if (!isLoggedIn || (isLoggedIn && !isLoggedIn.me))
    return <Navigate to="/welcome" replace />;
}

export default function Instructor() {
  const qAuthLink = useQuery(GITHUB_AUTH_LINK);
  const qMe = useQuery(ME);
  const [authorize, resAuthorize] = useMutation(AUTHORIZE);
  const [searchParams, setSearchParams] = useSearchParams();
  const loading = [qAuthLink.loading, qMe.loading, resAuthorize.loading];
  const error = [qAuthLink.error, qMe.error, resAuthorize.error];
  const logout = () => {
    localStorage.removeItem("authorization");
    qMe.client.resetStore();
    window.location.reload();
  };
  useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      setSearchParams("");
      authorize({ variables: { code } }).then(({ data }) => {
        localStorage.setItem("authorization", data.githubAuth.token);
        qMe.refetch();
        window.location = "/courses";
      });
    }
  }, []);

  if (loading.some(exist)) return <Container>loading</Container>;
  if (error.some(exist))
    return (
      <Container className="error">
        <p>an error occurred while logging in:</p>
        <p>{error.find(exist).message}</p>
        <p>
          <a href="/">ok</a>
        </p>
      </Container>
    );

  if (qMe.data.me)
    return (
      <Container>
        <InstructorUI {...qMe.data.me} onLogout={logout} />
      </Container>
    );

  if (qAuthLink.data)
    return (
      <Container>
        <GithubLogin
          onClick={() => (window.location = qAuthLink.data.githubUrl)}
        />
      </Container>
    );

  return null;
}

const Container = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;

  &.error {
    padding: 1em;
    background-color: #ffa0a0;
    font-family: Text;
    border-radius: 10px;
  }
`;
