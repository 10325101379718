import { Link } from "react-router-dom";
import { urlFriendly } from "../../../lib";
import {
  getTypeColor,
  TopicIcon,
  SubTitle,
  Column,
  TimeDisplay,
  Difficulty,
} from "../../ui";
import styled from "styled-components";

export function Section({ title, length, courseId, topics = [] }) {
  return (
    <Container>
      <SubTitle className="section-title">
        <Link to={`/preview/${courseId}/${urlFriendly(title)}`}>{title}</Link>
      </SubTitle>
      <TimeDisplay time={length} short={false} />
      <Column>
        {topics.map((topic) => (
          <Item key={urlFriendly(topic.title)} type={topic.type}>
            <TimeDisplay time={topic.length} short={true} />
            <TopicIcon className="topic-type" size={30} type={topic.type} />
            <Difficulty className="topic-difficulty" level={topic.difficulty} />
            <Link
              to={`/preview/${courseId}/${urlFriendly(title)}/${urlFriendly(
                topic.title
              )}`}
            >
              {topic.title}
            </Link>
          </Item>
        ))}
      </Column>
    </Container>
  );
}

export default function Sections({ courseId, agenda = [] }) {
  return agenda.map((topic) => (
    <Section key={urlFriendly(topic.title)} courseId={courseId} {...topic} />
  ));
}

const Container = styled.section`
  margin-left: 70px;

  .section-title {
    margin-top: 1em;
    margin-right: 1em;
  }

  a {
    text-decoration: none;
    color: black;
  }

  > div {
    margin-bottom: 20px;
  }
`;

const Item = styled.div`
  margin-left: 2em;
  font-family: Text;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3px;

  svg.topic-type {
    margin-right: 15px;
    fill: ${(props) => {
      return getTypeColor({ type: props.type, required: true });
    }};
    path {
      stroke: ${(props) => getTypeColor({ type: props.type, required: true })};
    }
  }

  a {
    margin-left: 15px;
  }
`;
