import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { createRoot } from "react-dom/client";
import App from "./components/App";

if (!process.env.REACT_APP_GRAPHQL_API) {
  throw new Error(
    "Environment variable for process.env.REACT_APP_GRAPHQL_API was not found!"
  );
}

if (!process.env.REACT_APP_SUBSCRIPTION_WS) {
  throw new Error(
    "Environment variable for process.env.REACT_APP_GRAPHQL_API was not found!"
  );
}

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("authorization");
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const secureLink = authLink.concat(httpLink);

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_SUBSCRIPTION_WS,
  })
);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  secureLink
);

const cache = new InMemoryCache();
const client = new ApolloClient({ link, cache });

createRoot(document.getElementById("root")).render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);
