import { gql, useQuery } from "@apollo/client";
import { getTypeColor } from "../../../ui";
import styled from "styled-components";

const FLAT_COURSE = gql`
  query course($courseId: ID!) {
    getFlatCourse(id: $courseId) {
      title
      type
    }
  }
`;

export default function CourseProgressBar({ courseId }) {
  const { data, loading, error } = useQuery(FLAT_COURSE, {
    variables: { courseId },
  });

  if (loading) return <p>loading</p>;
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

  const [, ...indicators] = data.getFlatCourse.filter((t) => t.type !== "step");

  return (
    <Container onClick={(e) => (window.location = "/")}>
      {indicators.map((topic, i) =>
        topic.type === "section" ? (
          <Section />
        ) : (
          <TopicIndicator type={topic.type} required={true} />
        )
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Section = styled.div`
  height: 20px;
  border-left: solid 3px grey;
  margin: 0 5px;
`;

const TopicIndicator = styled.div`
  height: 10px;
  width: 10px;
  border: solid 3px ${getTypeColor};
  border-radius: 2px;
  margin: 5px;
`;
