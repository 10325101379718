import { Link } from "react-router-dom";
import { createStackLink } from "../../lib";
import { BiSkipPreviousCircle, BiSkipNextCircle } from "react-icons/bi";
import styled from "styled-components";

export function PrevNextMenu({ courseId, prev, next }) {
  return (
    <Container>
      <Link to={createStackLink(prev, courseId)}>
        <Button>
          <BiSkipPreviousCircle size={42} />
          <span>{prev ? prev.title : "Course Contents"}</span>
        </Button>
      </Link>

      <Link to={createStackLink(next, courseId)}>
        <Button>
          <span>{next ? next.title : "Course Contents"}</span>
          <BiSkipNextCircle size={42} />
        </Button>
      </Link>
    </Container>
  );
}

const Button = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  opacity: 0.7;
`;

const Container = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;

  a {
    font-family: Text;
    color: black;
    text-decoration: none;
  }

  span {
    cursor: pointer;
    background: none;
    border: none;
    position: relative;
    text-decoration: none;
    margin: 0.25em;
    font-size: 1em;
  }
`;
