import styled from "styled-components";

export const Title = styled.h1`
  font-family: Text;
  font-size: 3em;
`;

export const SubTitle = styled.h2`
  font-family: Text;
  font-size: 1.5em;
`;

export const Text = styled.p`
  font-family: Text;
  padding: 0;
  margin: 0;
`;
