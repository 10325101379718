import CourseProgressBar from "./CourseProgressBar";
import { Link } from "react-router-dom";
import { Title, Row } from "../../ui";
import styled from "styled-components";

function Breadcrumbs({ courseId, breadcrumbs = [] }) {
  const [, ...crumbs] = breadcrumbs.map((text, key, arr) => {
    const to =
      key > 1
        ? `/preview/${courseId}/${arr[key - 1]}/${text}`
        : `/preview/${courseId}/${text}`;
    return {
      key,
      text,
      to,
    };
  });
  return (
    <Row>
      {crumbs.map(({ key, to, text }) => (
        <Link key={key} to={to}>
          {text}
        </Link>
      ))}
    </Row>
  );
}

export default function TopicTitle({
  title = "Untitled Topic",
  breadcrumbs = [],
  courseId,
}) {
  return (
    <Container>
      <Row>
        <Title>{title}</Title>
      </Row>
      <Breadcrumbs courseId={courseId} breadcrumbs={breadcrumbs} />
      <CourseProgressBar courseId={courseId} />
    </Container>
  );
}

const Container = styled.div`
  grid-area: 1 / 2 / 2 / 6;
  display: flex;
  padding: 1em;
  flex-direction: column;

  h1 {
    font-size: 2.5em;
  }

  a {
    text-decoration: none;
    color: blue;
    &:after {
      content: " > ";
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
`;
