import { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { findParent, findTopic } from "../lib";

export const FLAT_COURSE = gql`
  query course($courseId: ID!) {
    getFlatCourse(id: $courseId) {
      title
      type
      length
      difficulty
      breadcrumbs
      content
    }
  }
`;

export function useCurrentTopic(courseId, key) {
  const [parent, setParent] = useState();
  const { data, loading, error } = useQuery(FLAT_COURSE, {
    variables: { courseId },
  });
  useEffect(() => {
    if (!data) return;
    const { index, topic } = findTopic(key, data.getFlatCourse);
    setParent(findParent(index, data.getFlatCourse));
  }, [key, data]);

  if (data) {
    const { topic, next, prev } = findTopic(key, data.getFlatCourse);
    return {
      parent,
      topic,
      next,
      prev,
      loading,
      error,
    };
  }
  return {
    parent,
    loading,
    error,
    parent: null,
    next: null,
    prev: null,
    topic: null,
  };
}
