import { Timer } from ".";
import styled from "styled-components";

export function TimeDisplay({
  time,
  locked = false,
  size = 18,
  short = false,
  ...props
}) {
  if (time >= 60) {
    let h = Math.floor(time / 60);
    let m = time % 60;
    const message = short
      ? `${h}hr${h > 1 ? "s" : ""} ${m > 0 ? `${m}min` : ""}`
      : `${h}hour${h > 1 ? "s" : ""} ${m > 0 ? `${m}minutes` : ""}`;

    return (
      <>
        <Timer size={size} {...props} />
        {message}
      </>
    );
  }

  if (time) {
    return (
      <Container short={short}>
        <Timer size={size} {...props} /> &nbsp;
        <span {...props}>
          {time} min{time === 1 ? "" : "s"}
        </span>
      </Container>
    );
  }

  return null;
}

const Container = styled.div`
  width: ${(props) => (props.short ? "90px" : "200px")};
  display: flex;
  align-items: center;
`;
