import styled from "styled-components";

export function InstructorUI({ avatar, login, name, onLogout = (f) => f }) {
  return (
    <Container>
      <img src={avatar} />
      <div>
        <p>{login}</p>
        <p>{name}</p>
        <p className="logout" onClick={onLogout}>
          logout
        </p>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 75px;
    border-radius: 50%;
    margin: 10px;
  }

  p {
    padding: 0;
    margin: 0;
  }

  .logout {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
`;
