import { useParams } from "react-router-dom";
import { useCurrentTopic } from "../../../hooks";
import { LoginRequired } from "../../Instructor";
import { PrevNextMenu } from "../../ui";
import Section from "./Section";
import Exercise from "./Exercise";
import Lab from "./Lab";
import Step from "./Step";
import Slides from "./Slides";
import Sample from "./Sample";

export default function Preview() {
  const { courseId, sectionTitle, topicTitle, stepTitle } = useParams();
  const { loading, topic, next, prev, parent, error } = useCurrentTopic(
    courseId,
    stepTitle || topicTitle || sectionTitle
  );

  if (loading) return <p>loading</p>;
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

  return (
    <>
      <LoginRequired />
      {topic.type === "slides" ? (
        <Slides title={topic.title} time={topic.length} />
      ) : topic.type === "section" ? (
        <Section title={topic.title} time={topic.length} />
      ) : topic.type === "exercise" ? (
        <Exercise title={topic.title} time={topic.length} />
      ) : topic.type === "lab" ? (
        <Lab title={topic.title} time={topic.length} />
      ) : topic.type === "sample" ? (
        <Sample title={topic.title} time={topic.length} />
      ) : (
        <Step
          title={topic.title}
          courseId={courseId}
          parentType={parent && parent.type}
          time={parent && parent.length}
          breadcrumbs={topic.breadcrumbs}
          type={topic.type}
          required={topic.required}
          md={topic.content}
        />
      )}
      <PrevNextMenu courseId={courseId} prev={prev} next={next} />
    </>
  );
}
