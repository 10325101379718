import { getTypeColor, TopicIcon } from "../../ui";
import styled from "styled-components";

export default function TopicIconInfo({ type, children }) {
  return (
    <Container type={type} required={true}>
      {children}
      <TopicIcon
        className="topic-icon"
        type={type}
        size={50}
        stroke="black"
        fill="black"
      />
    </Container>
  );
}

const Container = styled.div`
  grid-area: 1 / 1 / 7 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: -webkit-linear-gradient(
    138deg,
    #fff 37%,
    ${getTypeColor} 35%
  );
  .topic-icon {
    margin-top: 70px;
  }
`;
