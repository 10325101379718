import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import CourseTitle from "./CourseTitle";
import Sections from "./Sections";
import { LoginRequired } from "../../Instructor";
import styled from "styled-components";

export const COURSE = gql`
  query course($courseId: ID!) {
    getCourse(id: $courseId) {
      id
      title
      length
      agenda {
        length
        title
        topics {
          title
          type
          difficulty
          length
          steps {
            title
            required
          }
        }
      }
    }
  }
`;

export default function TOC() {
  const { courseId } = useParams();
  const { data, loading, error } = useQuery(COURSE, {
    variables: { courseId },
  });

  if (loading) return <p>loading</p>;
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

  const {
    getCourse: { id, title, agenda, length },
  } = data;

  return (
    <Container>
      <LoginRequired />
      <CourseTitle title={title} length={length} />
      <Sections agenda={agenda} courseId={id} />
    </Container>
  );
}

const Container = styled.section``;
