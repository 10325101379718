import { BrowserRouter, Routes, Route } from "react-router-dom";
import Instructor from "./Instructor";
import Welcome from "./Screens/Welcome";
import TOC from "./Screens/TOC";
import Courses from "./Screens/Courses";
import Preview from "./Screens/Preview";
import { GlobalStyle } from "./ui";

export default function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Instructor />
      <Routes>
        <Route path="/" element={<Courses />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/contents/:courseId" element={<TOC />} />
        <Route path="preview/*">
          <Route
            path=":courseId/:sectionTitle/:topicTitle/:stepTitle"
            element={<Preview />}
          />
          <Route
            path=":courseId/:sectionTitle/:topicTitle"
            element={<Preview />}
          />
          <Route path=":courseId/:sectionTitle" element={<Preview />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
