import styled from "styled-components";

export const Container = styled.div``;

export const MiddlePage = styled.main`
  height: ${(props) =>
    props.size === "sm" ? "50%" : props.size === "md" ? "75%" : "100%"};
  margin-top: ${(props) =>
    props.size === "sm" ? "200px" : props.size === "md" ? "100px" : "0"};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const PrevNextBar = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Centered = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
