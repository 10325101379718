import ts_logo from "../../assets/tslogo-dark-gradient1.svg";
import mh_logo from "../../assets/mhlogo-indigo.svg";
import { Logo, MiddlePage } from "../ui";

export default function Welcome() {
  return (
    <MiddlePage size="md">
      <Logo size="sm" src={mh_logo} alt="Moon Highway Logo" />
      <Logo size="md" src={ts_logo} alt="Timesplitter Logo" />
    </MiddlePage>
  );
}
