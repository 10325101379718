export * from "./findParent";
export * from "./totalTime";
export * from "./createStackLink";
export * from "./findTopic";

export const toJSON = (res) => res.json();
export const toText = (res) => res.text();

export const throwIt = (msg) => (error) => {
  if (msg) {
    console.error(msg);
  }
  throw error;
};

export const toMilliseconds = (time) => time * 60 * 1000;

export function round(value, precision = 0) {
  var multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
}

export const urlFriendly = (str) => str.trim().toLowerCase().replace(/ /g, "-");
export const titlesOnly = ({ title }) => urlFriendly(title);
export const byTitle = (title) => (topic) =>
  urlFriendly(topic.title) === urlFriendly(title);
