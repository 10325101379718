export function findParent(index, topics) {
  let parent;
  for (let i = index; i > 0; i--) {
    if (topics[i].type !== "step") {
      parent = topics[i];
      break;
    }
  }
  return parent;
}
