import * as mdxComponents from "../../mdx-components";
import { CodeBlock, BookStyles } from "../../ui";
import { useMDX } from "../../../hooks";
import TopicTime from "./TopicTime";
import TopicIconInfo from "./TopicIconInfo";
import TopicTitle from "./TopicTitle";
import styled from "styled-components";

export default function Step({
  title,
  time,
  breadcrumbs,
  md,
  parentType,
  courseId,
}) {
  const exports = useMDX(md);
  const Content = exports.default;

  if (!exports) return <p>loading...</p>;

  return (
    <Container>
      <TopicIconInfo type={parentType}>
        <TopicTime total={time} strokeColor="black" fillColor="transparent" />
      </TopicIconInfo>
      <TopicTitle title={title} courseId={courseId} breadcrumbs={breadcrumbs} />
      <Contents>
        <BookStyles>
          <Content components={{ ...mdxComponents, code: CodeBlock }} />
        </BookStyles>
      </Contents>
    </Container>
  );
}

const Container = styled.article`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

const Contents = styled.div`
  margin-left: 1em;
  grid-area: 2 / 2 / 8 / 7;
  overflow-y: scroll;
`;
