import { urlFriendly } from ".";

export function createStackLink(topic, courseId) {
  if (!topic) return `/contents/${courseId}`;

  let { title, breadcrumbs, type } = topic;
  let [, ...route] = breadcrumbs;
  return type === "course"
    ? `/contents/${courseId}`
    : route.length
    ? urlFriendly(`/preview/${courseId}/${route.join("/")}/${title}`)
    : urlFriendly(`/preview/${courseId}/${title}`);
}
