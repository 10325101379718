import { urlFriendly } from ".";

export function findTopic(key, topics = []) {
  if (!key)
    throw new Error("A key is required to find a topic. findTopic(key,topics)");

  let index;
  const topic = topics.find((t, i) => {
    index = i;
    return key === urlFriendly(t.title);
  });

  return {
    index,
    topic,
    next: topics[index + 1],
    prev: topics[index - 1],
  };
}
