import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Title, Text, Row } from "../../ui";
import styled from "styled-components";

export default function CourseTitle({ title, length }) {
  const courseStart = new Date();
  const courseEnd = new Date();
  courseEnd.setMinutes(courseEnd.getMinutes() + length);

  return (
    <Container>
      <Row>
        <Link to="/">
          <BiArrowBack size={30} color="blue" />
        </Link>
        <Title>{title}</Title>
      </Row>
      <Text className="date">
        {format(courseStart, "eeee MMMM do yyyy - h:mmaaa ")}
        {format(courseEnd, "- h:mmaaa ")}
      </Text>
    </Container>
  );
}

const Container = styled.header`
  display: flex;
  padding: 1em;
  flex-direction: column;
  svg {
    margin-right: 1em;
  }
  .date {
    font-size: 1.5em;
    margin-left: 50px;
  }
`;
