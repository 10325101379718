import { useState, useEffect } from "react";
import * as runtime from "react/jsx-runtime";
import { evaluate } from "@mdx-js/mdx";
import { remarkMdxCodeMeta } from "remark-mdx-code-meta";
import { visit } from "unist-util-visit";

export function useMDX(content) {
  const [exports, setExports] = useState({ default: runtime.Fragment });

  useEffect(() => {
    evaluate(content, {
      jsx: true,
      rehypePlugins: [
        () => (tree) => {
          visit(tree, "element", (node) => {
            if (node.tagName === "code" && node.data && node.data.meta) {
              const props = node.data.meta.split(" ");
              props.forEach((prop) => {
                const [name, value] = prop.split("=");
                node.properties[name] = value;
              });
            }
          });
        },
      ],
      ...runtime,
    }).then(setExports);
  }, [content]);

  return exports;
}
