import { Text, MiddlePage } from "../ui";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { LoginRequired } from "../Instructor";

const ALL_COURSES = gql`
  {
    allCourses {
      id
      title
    }
  }
`;

export default function Courses() {
  const { loading, error, data } = useQuery(ALL_COURSES);

  if (loading) return <Text>Loading</Text>;
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

  return (
    <MiddlePage size="md">
      <LoginRequired />
      {data.allCourses.map((c) => (
        <Link key={c.id} to={`/contents/${c.id}`}>
          <Text>{c.title}</Text>
        </Link>
      ))}
    </MiddlePage>
  );
}
