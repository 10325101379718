import { FaGithub } from "react-icons/fa";
import styled from "styled-components";

export function GithubLogin({ style = {}, onClick = (f) => f }) {
  return (
    <Button style={style} onClick={onClick}>
      <FaGithub />
      Sign In with Github
    </Button>
  );
}

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  font-family: "Text";

  &:hover {
    color: #3d2762;
  }
  svg {
    margin-right: 6px;
    font-size: 1.5em;
  }

  font-size: 1.7em;
`;
